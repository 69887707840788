<!-- Footer-->
<!-- Mail subscription -->
<!-- Footer-->
<div class="footer bg-secondary">
    <section class="container">
        <div class="card py-5 border-0 shadow">
            <div class="card-body py-md-4 py-3 px-4 text-center">
                <h3 class="mb-3">Em breve você pode criar sua campanha!</h3>
                <p class="mb-4 pb-2">
                    Inscreva-se e fique por dentro das novidades.
                </p>
                <div class="widget mx-auto" style="max-width: 600px;">
                    <form (ngSubmit)="subscribe()" [formGroup]="formData" novalidate>
                        <div class="row g-3">
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap">
                                    <i
                                        class="ci-user position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>
                                    <input class="form-control rounded-start" type="text" placeholder="Seu nome"
                                        required formControlName="name"
                                        [ngClass]="{ 'is-invalid': submitted && form['name'].errors }">
                                </div>
                                <div *ngIf="submitted && form['name'].errors"
                                    class="invalid-feedback d-block text-start mt-2">
                                    <small *ngIf="form['name'].errors?.['required']">Nome é obrigatório.</small>
                                </div>
                            </div>

                            <div class="col-md-8">
                                <div class="input-group flex-nowrap">
                                    <i
                                        class="ci-mail position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>
                                    <input class="form-control rounded-start" type="text"
                                        placeholder="Seu e-mail ou telefone" required formControlName="contact"
                                        [ngClass]="{ 'is-invalid': submitted && form['contact'].errors }">
                                    <button class="btn btn-accent" type="submit">
                                        Inscrever-se*
                                    </button>
                                </div>
                                <div *ngIf="submitted && form['contact'].errors"
                                    class="invalid-feedback d-block text-start mt-2">
                                    <small *ngIf="form['contact'].errors?.['required']">Contato é obrigatório.</small>
                                    <small *ngIf="form['contact'].errors?.['pattern']">Por favor, insira um e-mail ou
                                        telefone válido.</small>
                                </div>
                            </div>
                        </div>

                        <div class="form-text mt-3">
                            *Receba informações e novidades da rifa.live, por telefone ou e-mail.
                        </div>

                        <div class="subscription-status mt-3">
                            <div *ngIf="successMessage" class="alert alert-success">{{ successMessage }}</div>
                            <div *ngIf="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>

<footer class="footer bg-darker">
    <div class="mt-n10 pt-10 bg-dark">
        <div class="container py-5">
            <div class="row py-lg-4">
                <div class="col-lg-4 mb-lg-0 mb-4">
                    <div class="widget widget-links widget-light">
                        <h3 class="widget-title text-light"></h3>
                        <p class="text-light opacity-70"></p>
                    </div>
                </div>
                <div class="col-lg-6 offset-lg-1">
                    <div class="d-flex flex-sm-row flex-column justify-content-sm-between mt-n4 mx-lg-n3">
                        <div class="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                            <h3 class="widget-title text-light"></h3>
                            <ul class="widget-list">
                                <li class="widget-list-item">
                                    <a class="widget-list-link" [routerLink]="['/my-quotas']">Meus números</a>
                                </li>
                                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);"></a>
                                </li>
                                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);"></a>
                                </li>
                            </ul>
                        </div>
                        <div class="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                            <h3 class="widget-title text-light"></h3>
                            <ul class="widget-list">
                                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);"></a>
                                </li>
                                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);"></a>
                                </li>
                                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);"></a>
                                </li>
                            </ul>
                        </div>
                        <div class="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                            <h3 class="widget-title text-light"></h3>
                            <ul class="widget-list">
                                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);"></a>
                                </li>
                                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);"></a>
                                </li>
                                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);"></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4">
        <div class="d-flex flex-md-row flex-column-reverse align-items-center justify-content-md-between">
            <div class="fs-xs text-light opacity-50">&copy; <a class="text-light" href="https://rede5.com.br"
                    target="_blank" rel="noopener"></a></div>
            <div class="d-flex align-items-start mb-md-0 mb-3 mx-n1">
                
            </div>
        </div>
    </div>
</footer>


<div class="handheld-toolbar"></div>
<!-- Back To Top Button-->
<a class="btn-scroll-top" href="javascript:void(0)" [ngxScrollTo]="'#home'" data-scroll>
    <span class="btn-scroll-top-tooltip text-muted fs-sm me-2">Topo</span>
    <i class="btn-scroll-top-icon ci-arrow-up"></i>
</a>