import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NewsletterService } from '../services/newsletter.service';
import { Newsletter } from '../models/newsletter.model';  // Importando o model

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  formData!: UntypedFormGroup;
  submitted = false;
  successMessage: string = '';
  errorMessage: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private newsletterService: NewsletterService
  ) { }

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      name: ['', [Validators.required]],  // Campo de nome
      contact: ['', [Validators.required]]  // Campo de contato (email ou telefone)
    });
  }

  /**
   * Retorna os controles do formulário
   */
  get form() {
    return this.formData.controls;
  }

  subscribe() {
    this.submitted = true;

    if (this.formData.invalid) {
      return;
    }

    const formData: Newsletter = {
      name: this.formData.get('name')?.value,  // Obtendo o nome
      contact: this.formData.get('contact')?.value  // Obtendo o contato (email ou telefone)
    };

    this.newsletterService.subscribeNewsletter(formData).subscribe(
      response => {
        this.successMessage = 'Inscrição realizada com sucesso!';
        this.formData.reset();
        this.submitted = false;
      },
      error => {
        this.errorMessage = 'Erro ao tentar se inscrever. Tente novamente.';
        console.error('Erro:', error);
      }
    );
  }
}
