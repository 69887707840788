import { MenuItem } from './menu.model';  // Importa o modelo 'MenuItem'

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Meus números',
        link: '/my-quotas',
    },
    // {
    //     id: 2,
    //     label: 'Catalog',
    //     subItems: [
    //         {
    //             id: 3,
    //             label: 'Catalog v.1',
    //             link: '/catalog_v1',
    //             parentId: 2
    //         },
    //         {
    //             id: 4,
    //             label: 'Catalog v.2',
    //             link: '/catalog_v2',
    //             parentId: 2
    //         },
    //         {
    //             id: 5,
    //             label: 'Single Item - Auction Live',
    //             link: '/auctionlive',
    //             parentId: 2
    //         },
    //         {
    //             id: 6,
    //             label: 'Single Item - Auction Ended',
    //             link: '/auctionended',
    //             parentId: 2
    //         },
    //         {
    //             id: 7,
    //             label: 'Single Item - Buy Now',
    //             link: '/auctionbuy',
    //             parentId: 2
    //         },
    //         {
    //             id: 8,
    //             label: 'Vendor Page',
    //             link: '/vendor',
    //             parentId: 2
    //         },
    //         {
    //             id: 9,
    //             label: 'Connect Wallet',
    //             link: '/connectwallet',
    //             parentId: 2
    //         },
    //         {
    //             id: 10,
    //             label: 'Create New Item',
    //             link: '/createnewitem',
    //             parentId: 2
    //         },
    //     ]
    // },

    // {
    //     id: 11,
    //     label: 'Account',
    //     subItems: [
    //         {
    //             id: 12,
    //             label: 'Profile Settings',
    //             link: '/setting',
    //             parentId: 11
    //         },
    //         {
    //             id: 13,
    //             label: 'My Items',
    //             link: '/myitem',
    //             parentId: 11
    //         },
    //         {
    //             id: 14,
    //             label: 'My Collections',
    //             link: '/mycollection',
    //             parentId: 11
    //         },
    //         {
    //             id: 15,
    //             label: 'Favorites',
    //             link: '/favorite',
    //             parentId: 11
    //         },
    //         {
    //             id: 16,
    //             label: 'Notifications',
    //             link: '/notification',
    //             parentId: 11
    //         },
    //     ]
    // },

    // {
    //     id: 17,
    //     label: 'Back to main demo',
    //     link: '/',
    //     isTitle: true
    // }
];