<!-- Navbar para o NFT Marketplace demo -->
<!-- Mantendo a detecção de scroll para a navbar -->
<div (window:scroll)="windowScroll()" id="home">
    <header class="navbar d-block navbar-sticky navbar-expand-lg navbar-light bg-light w-100">
        <div class="container">

            <a class="navbar-brand d-none d-sm-block me-4 order-lg-1" routerLink="/">
                <img src="assets/img/logo-dark.png" width="142" alt="Rifa Live">
            </a>
            <a class="navbar-brand d-sm-none me-2 order-lg-1" routerLink="/">
                <img src="assets/img/logo-icon.png" width="74" alt="Rifa Live">
            </a>

            <div class="navbar-toolbar d-flex align-items-center order-lg-3">
                <!--<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span class="navbar-toggler-icon"></span>
                </button>-->

                <!-- Parte de Busca comentada -->
                <!--
                <a class="navbar-tool d-none d-lg-flex" href="javascript:void(0)" data-bs-toggle="collapse"
                    data-bs-target="#searchBox" role="button" aria-expanded="false" aria-controls="searchBox">
                    <span class="navbar-tool-tooltip">Pesquisar</span>
                    <div class="navbar-tool-icon-box">
                        <i class="navbar-tool-icon ci-search"></i>
                    </div>
                </a>
                -->

                <!-- Mantendo esta parte sem comentários -->
                <a class="navbar-tool ms-lg-2 account" href="javascript:void(0);" (click)="openModal(content)">
                    <span class="navbar-tool-tooltip">Conta</span>
                    <div class="navbar-tool-icon-box"><i class="navbar-tool-icon ci-user"></i></div>
                </a>

                <!--
                <div ngbDropdown class="navbar-tool dropdown ms-lg-3 ms-2 user">
                    <a class="navbar-tool-icon-box d-inline-block w-auto h-auto dropdown-toggle mt-n1"
                        routerLink="/setting" ngbDropdownToggle>
                        <img class="rounded-circle" src="assets/img/nft/vendor/avatar-sm.png" width="36" alt="Usuário"
                            id="user_profile">
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
                        <div style="min-width: 14rem;">
                            <h6 class="dropdown-header">&#64;foxnet_creator</h6>
                            <a class="dropdown-item d-flex align-items-center" routerLink="/setting">
                                <i class="ci-settings opacity-60 me-2"></i>Configurações de Perfil
                            </a>
                            <a class="dropdown-item d-flex align-items-center" routerLink="/myitem">
                                <i class="ci-image opacity-60 me-2"></i>Meus Itens
                                <span class="fs-xs text-muted ms-auto">5</span>
                            </a>
                            <a class="dropdown-item d-flex align-items-center" routerLink="/mycollection">
                                <i class="ci-view-list opacity-60 me-2"></i>Minhas Coleções
                                <span class="fs-xs text-muted ms-auto">2</span>
                            </a>
                            <a class="dropdown-item d-flex align-items-center" routerLink="/favorite">
                                <i class="ci-heart opacity-60 me-2"></i>Favoritos
                                <span class="fs-xs text-muted ms-auto">3</span>
                            </a>
                            <a class="dropdown-item d-flex align-items-center" routerLink="/notification">
                                <i class="ci-bell opacity-60 me-2"></i>Notificações
                            </a>
                        </div>
                    </div>
                </div>
                -->

                <!-- Botão de "Conectar Carteira" comentado -->
                <!--
                <a class="btn btn-sm btn-accent rounded-1 ms-lg-4 ms-2 wallet" routerLink="/connectwallet">
                    Conectar Carteira
                </a>
                -->

                <!--
                <a class="btn btn-sm btn-accent rounded-1 ms-lg-4 ms-3 create" routerLink="/createnewitem">Criar</a>
                -->
            </div>


            <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed"
                class="collapse navbar-collapse me-auto order-lg-2" id="navbarCollapse">
                <!--  <div class="d-lg-none py-3">

                    Parte de Busca para Mobile comentada 
                    <div class="input-group">
                        <i class="ci-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                        <input class="form-control rounded-start" type="text" placeholder="O que você precisa?">
                    </div>

                </div> -->
                <ul class="navbar-nav" id="navbar-nav">
                    <li *ngFor="let item of menuItems" [ngClass]="{'active': item.isTitle}">
                        <a class="nav-link sublink" [routerLink]="item.link" (click)="updateActive($event)">
                            {{item.label}}
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </header>
</div>

<!-- Modal de login/cadastro comentado -->
<ng-template #content let-modal>
    <div class="modal-content">
        <div class="modal-header bg-secondary">
            <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs card-header-tabs" role="tablist">
                <li class="nav-item" [ngbNavItem]="1">
                    <a ngbNavLink class="nav-link fw-medium" href="javascript:void(0);" role="tab">
                        <i class="ci-unlocked me-2 mt-n1"></i>Entrar
                    </a>
                    <ng-template ngbNavContent>
                        <form (ngSubmit)="signin()" [formGroup]="signinForm"
                            class="needs-validation tab-pane fade show">
                            <div class="mb-3">
                                <label class="form-label" for="si-email">Endereço de e-mail</label>
                                <input class="form-control" type="email" id="si-email" placeholder="johndoe@example.com"
                                    formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && signinForm.controls['email'].errors }">
                                <div *ngIf="submitted && signinForm.controls['email'].errors" class="invalid-feedback">
                                    <span>Por favor, insira um endereço de e-mail válido.</span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="si-password">Senha</label>
                                <div class="password-toggle">
                                    <input class="form-control" [type]="fieldTextType ? 'text' : 'password'"
                                        id="si-password" formControlName="password"
                                        [ngClass]="{ 'is-invalid': submitted && signinForm.controls['password'].errors }">
                                    <div *ngIf="submitted && signinForm.controls['password'].errors"
                                        class="invalid-feedback">
                                        <span>Por favor, insira uma senha válida.</span>
                                    </div>
                                    <label class="password-toggle-btn" aria-label="Mostrar/ocultar senha">
                                        <input class="password-toggle-check" type="checkbox">
                                        <span class="password-toggle-indicator"
                                            [ngClass]="fieldTextType ? 'mdi-eye-off-outline':'mdi-eye-off-outline'"
                                            (click)="toggleFieldTextType()"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-wrap justify-content-between">
                                <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" id="si-remember">
                                    <label class="form-check-label" for="si-remember">Lembrar de mim</label>
                                </div>
                                <a class="fs-sm" href="javascript:void(0);">Esqueceu a senha?</a>
                            </div>
                            <button class="btn btn-primary btn-shadow d-block w-100" type="submit">Entrar</button>
                        </form>
                    </ng-template>
                </li>

                <!-- Parte de cadastro comentada -->
                <!--
                <li class="nav-item" [ngbNavItem]="2">
                    <a ngbNavLink class="nav-link fw-medium" href="javascript:void(0);" role="tab">
                        <i class="ci-user me-2 mt-n1"></i>Cadastrar
                    </a>
                    <ng-template ngbNavContent>
                        <form (ngSubmit)="signup()" [formGroup]="signupForm"
                            class="needs-validation tab-pane fade show">
                            <div class="mb-3">
                                <label class="form-label" for="su-name">Nome completo</label>
                                <input class="form-control" type="text" id="su-name" placeholder="John Doe"
                                    formControlName="name"
                                    [ngClass]="{ 'is-invalid': signupsubmit && signupForm.controls['name'].errors }">
                                <div *ngIf="signupsubmit && signupForm.controls['name'].errors"
                                    class="invalid-feedback">
                                    <span>Por favor, insira seu nome completo.</span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="su-email">Endereço de e-mail</label>
                                <input class="form-control" type="email" id="su-email" placeholder="johndoe@example.com"
                                    formControlName="email"
                                    [ngClass]="{ 'is-invalid': signupsubmit && signupForm.controls['email'].errors }">
                                <div *ngIf="signupsubmit && signupForm.controls['email'].errors"
                                    class="invalid-feedback">
                                    <span>Por favor, insira um endereço de e-mail válido.</span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="su-password">Senha</label>
                                <div class="password-toggle">
                                    <input class="form-control" [type]="signupPassfield ? 'text' : 'password'"
                                        id="su-password" formControlName="password"
                                        [ngClass]="{ 'is-invalid': signupsubmit && signupForm.controls['password'].errors }">
                                    <div *ngIf="signupsubmit && signupForm.controls['password'].errors"
                                        class="invalid-feedback">
                                        <span>Por favor, insira uma senha válida.</span>
                                    </div>
                                    <label class="password-toggle-btn" aria-label="Mostrar/ocultar senha">
                                        <input class="password-toggle-check" type="checkbox">
                                        <span class="password-toggle-indicator"
                                            (click)="togglesignupPassfield()"></span>
                                    </label>
                                </div>
                            </div>
                            <button class="btn btn-primary btn-shadow d-block w-100" type="submit">Cadastrar</button>
                        </form>
                    </ng-template>
                </li>
                -->
            </ul>
            <button class="btn-close" type="button" aria-label="Fechar" (click)="modal.close('close click')"></button>
        </div>
        <div class="modal-body tab-content py-4">
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</ng-template>



<!-- Barra de ferramentas para dispositivos móveis comentada -->
<!--
<div class="handheld-toolbar">
    <div class="d-table table-layout-fixed w-100">

        Botão de "Conectar Carteira" comentado 
        <a class="d-table-cell handheld-toolbar-item connectwallet" routerLink="/connectwallet">
            <span class="handheld-toolbar-icon">
                <i class="ci-wallet"></i>
            </span>
            <span class="handheld-toolbar-label text-nowrap">Conectar carteira</span>
        </a>

        <a class="d-table-cell handheld-toolbar-item craeteitem" routerLink="/createnewitem">
            <span class="handheld-toolbar-icon">
                <i class="ci-add"></i>
            </span>
            <span class="handheld-toolbar-label">Criar item</span>
        </a>
    </div>
</div>
-->